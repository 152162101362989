import React, { useMemo, useState } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  BoxProps,
  Text,
  Image,
} from '@chakra-ui/react';
import { SkipNavLink } from '@chakra-ui/skip-nav';
import { useAppSelector } from '@spartalabs/pdc-core';
import isotipoSvg from 'assets/svg/isotipo.svg';
import logoSvg from 'assets/svg/logo.svg';
import { isProd } from 'utils/functions';
import { NavBtn } from '../NavButtons';

const dropContent = {
  report: [
    {
      name: 'Modelos',
      path: '/relatorios/modelos',
    },
    {
      name: 'Distribuição da Frota',
      path: '/relatorios/distdefrotas',
    },
    { name: 'Disponibilidade', path: '/relatorios/disponibilidade' },
    {
      name: 'Manutenção',
      path: '/manutencao',
      insideDrops: [
        {
          name: 'Entrada e Saída',
          path: '/relatorios/manutencao/entradasaida',
        },
        { name: 'Preventivas', path: '/relatorios/manutencao/preventivas' },
        { name: 'Serviços', path: '/relatorios/manutencao/servicos' },
      ],
    },
  ],
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isCollapsed: boolean;
  sideBarName: string;
}

interface optionProps {
  fp: string[];
  sp: string[];
  tp: string[];
}
export const SidebarContent = ({
  onClose,
  isCollapsed,
  sideBarName,
  ...rest
}: SidebarProps) => {
  const permissions = useAppSelector(
    state => state.authSlicer.user.permissions,
  );

  const [option, setOption] = useState<optionProps>({
    fp: ['home'],
    sp: [],
    tp: [],
  });

  const vehiclesDrop = useMemo(() => {
    const drop = [
      {
        name: 'Veículos/CRLV',
        path: 'gestao/veiculos',
      },
      {
        name: 'Cobrança de Avarias',
        path: 'gestao/cobrancas',
      },
    ];

    drop.push({
      name: 'Recall',
      path: 'gestao/recall',
    });

    if (permissions?.includes('fines'))
      drop.push({
        name: 'Infrações/Multas',
        path: 'gestao/multas',
      });

    return drop;
  }, [permissions]);

  const AdmDrop = useMemo(() => {
    const drop = [];

    if (permissions?.includes('communique'))
      drop.push({
        name: 'Comunicados',
        path: 'administrativo/comunicados',
      });

    if (permissions?.includes('logs')) {
      drop.push({
        name: 'Log de ações',
        path: 'administrativo/logs',
      });

      drop.push({
        name: 'Logs Pdv',
        path: 'administrativo/logspdv',
      });
    }

    if (permissions?.includes('access'))
      drop.push({
        name: 'Acessos',
        path: '/acessos',
      });

    return drop;
  }, [permissions]);

  return (
    <Box
      zIndex={10}
      bg="mutable.100"
      w={{ base: 'full', xl: isCollapsed ? '86px' : '248px' }}
      pos="fixed"
      h="full"
      px={isCollapsed ? '16px' : '12px'}
      pt="44px"
      transition=" width ease-in-out 0.3s"
      overflowY={isCollapsed ? 'visible' : 'scroll'}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      {...rest}
    >
      <Flex justifyContent="space-between" mb="34px" alignItems="center">
        <Image
          alt=""
          pl="8px"
          src={isCollapsed ? isotipoSvg : logoSvg}
          w="100px"
        />
        <CloseButton
          data-cy={`close-${sideBarName}`}
          display={{ base: 'flex', xl: 'none' }}
          onClick={onClose}
        />
      </Flex>
      <SkipNavLink>
        <Text textColor="text.100">Pular navegação</Text>
      </SkipNavLink>

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Início"
        path="/inicio"
      />
      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Relatórios"
        isDropable
        dropContent={dropContent.report}
        path="/relatorios"
      />
      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Agendamentos"
        path="/agendamentos"
      />

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Faturamento"
        path="/faturamento"
      />
      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Solicitações"
        path="/solicitacoes"
      />

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Vamos Controle"
        path="https://www.vamoscontrole.com.br/iweb2/loginvamoscontrole.aspx"
      />

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        name="Central de Dúvidas"
        path="/centraldeduvidas"
      />
      {!isProd() && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Gestão de contratos"
          path="/gestaodecontratos"
        />
      )}

      <NavBtn
        onClose={onClose}
        isCollapsed={isCollapsed}
        option={option}
        setOption={setOption}
        isDropable
        dropContent={vehiclesDrop}
        name="Gestão de Veículos"
        path="/gestao"
      />
      {['logs', 'access', 'communique']?.some(v =>
        permissions?.includes(v as Permission),
      ) && (
        <NavBtn
          onClose={onClose}
          isCollapsed={isCollapsed}
          option={option}
          setOption={setOption}
          name="Administrativo"
          isDropable
          dropContent={AdmDrop}
          path="/administrativo"
        />
      )}
    </Box>
  );
};
