import React from 'react';
import {
  Text,
  VStack,
  VisuallyHidden,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';

interface SimpleSelectProps extends TextareaProps {
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onchange: (value: any) => void;
  isDisabled?: boolean;
  ariaLabel?: string;
  label: string;
  options?: { name: string; id: string }[];
  placeholder: string;
  error?: string;
}

const SimpleTextArea: React.FC<SimpleSelectProps> = ({
  value,
  onchange,
  isDisabled = false,
  ariaLabel,
  label,
  placeholder,
  error,
  ...props
}) => {
  return (
    <VStack spacing="4px" align="start" w={props.w}>
      <Text color="text.300" fontSize="14px">
        {label}
      </Text>
      <Textarea
        defaultValue={value}
        placeholder={placeholder}
        onChange={e => onchange?.(e.target.value)}
        disabled={isDisabled}
        aria-label={ariaLabel}
        {...props}
      />

      {error && (
        <>
          <VisuallyHidden>
            <Text>Erro, {error}</Text>
          </VisuallyHidden>
          <Text
            aria-hidden="true"
            alignSelf="start"
            pl="8px"
            color="red.200"
            fontSize="sm"
          >
            {error}
          </Text>
        </>
      )}
    </VStack>
  );
};

export default SimpleTextArea;
