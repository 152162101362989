import React, { useEffect, useMemo, useState } from 'react';
import { HiOutlineFilter, HiOutlinePencil } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import Onboarding from 'components/Onboarding';
import SimpleInput from 'components/SimpleInput';
import { useDebounce } from 'hooks';
import { finesTexts, vehicleTexts } from 'utils/texts';

interface SearchProps {
  title: string;
  desc: string;
  placeHolder?: string;
  openFilter?: () => void;
  step?: number | null;
  isEdit?: boolean;
  hasTags?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters?: any;
  autoSearch?: string;
}

export const PageSearch: React.FC<SearchProps> = ({
  title,
  desc,
  placeHolder,
  openFilter,
  step,
  setFilters,
  autoSearch,
  hasTags = true,
  isEdit,
}) => {
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const { debouncedValue } = useDebounce(search, 1000);

  const handleFilters = () => {
    setFilters?.((filter: { text: string }) => ({
      ...filter,
      text: debouncedValue,
    }));
  };

  const handleTag = useMemo(() => {
    const length = search.length;

    if (length > 11) return 'Chassi';
    if (length > 2) return 'Placa';
    return null;
  }, [search]);

  useEffect(() => {
    if (autoSearch) setSearch(autoSearch);
  }, [autoSearch]);

  useEffect(() => {
    handleFilters();
  }, [debouncedValue]);

  return (
    <Flex
      borderRadius="8px"
      bg={title === 'Recall' ? 'transparent' : 'background'}
      flexDir="column"
      py={{ md: '40px', base: '24px' }}
      px="16px"
    >
      <Flex
        w="100%"
        mt={{ base: '0px', md: '-20px' }}
        mb={{ base: '-32px', md: '0px' }}
      >
        {isEdit && (
          <Button
            ml="auto"
            data-cy="edit"
            variant="outline"
            aria-label="Editar FAQS"
            onClick={() => navigate('/solicitacoes/gerenciar')}
            _hover={{ bg: 'grey.300' }}
            borderRadius="4px"
            w="40px"
            h="40px"
          >
            <Icon w="24px" h="24px" color="mutable.100" as={HiOutlinePencil} />
          </Button>
        )}

        <Button
          ml={isEdit ? '4' : 'auto'}
          data-cy="filter"
          variant="outline"
          onClick={openFilter}
          leftIcon={
            <Icon
              mr={{ base: '-8px', md: '0px' }}
              color="mutable.100"
              as={HiOutlineFilter}
              h="20px"
              w="20px"
            />
          }
          fontWeight={400}
        >
          <Text display={{ base: 'none', md: 'block' }} textColor="mutable.100">
            Filtrar
          </Text>
        </Button>
      </Flex>
      <Flex display={{ md: 'block', base: 'flex' }}>
        <Box>
          <Text
            textAlign={{ md: 'center', base: 'start' }}
            color="button"
            mr={{ md: '0px', base: '40px' }}
            fontSize={{ md: '32px', base: '20px' }}
            fontWeight={600}
          >
            {title}
          </Text>
          <Text
            textAlign={{ md: 'center', base: 'start' }}
            mr={{ md: '0px', base: '40px' }}
            mt="8px"
            color="text.100"
          >
            {desc}
          </Text>
        </Box>
      </Flex>
      <Onboarding
        step={2}
        contents={title === 'Veículos' ? vehicleTexts.step2 : finesTexts.step2}
        type={title === 'Veículos' ? 'vehicles' : 'fines'}
        isOpen={step === 2}
      >
        <Flex
          mx={{ md: '20%', base: '2.5%' }}
          w={{ md: '60%', base: '95%' }}
          flex={1}
          mt="32px"
        >
          {placeHolder && (
            <SimpleInput
              value={search}
              tagType={hasTags ? handleTag : null}
              onChange={a => setSearch(a.target.value)}
              mx="auto"
              textColor="black"
              _placeholder={{ textColor: 'black' }}
              placeholder={placeHolder}
              maxLength={17}
            />
          )}
        </Flex>
      </Onboarding>
    </Flex>
  );
};
