import React from 'react';
import {
  Text,
  VStack,
  Select,
  SelectProps,
  VisuallyHidden,
} from '@chakra-ui/react';

interface SimpleSelectProps extends SelectProps {
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onchange: (value: any) => void;
  isDisabled?: boolean;
  ariaLabel?: string;
  label?: string;
  options?: { name: string; id: string }[];
  placeHolder?: string;
  error?: string;
  labelColor?: string;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  value,
  onchange,
  isDisabled = false,
  ariaLabel,
  label,
  options,
  placeHolder,
  error,
  labelColor,
  ...props
}) => {
  return (
    <VStack spacing="4px" align="start" w={props.w}>
      <Text color={labelColor || 'text.300'} fontSize="14px">
        {label}
      </Text>
      <Select
        defaultValue={value}
        placeholder={placeHolder}
        onChange={e => onchange?.(e.target.value)}
        w="120px"
        disabled={isDisabled}
        aria-label={ariaLabel}
        {...props}
      >
        {options?.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
      {error && (
        <>
          <VisuallyHidden>
            <Text>Erro, {error}</Text>
          </VisuallyHidden>
          <Text
            aria-hidden="true"
            alignSelf="start"
            pl="8px"
            color="red.200"
            fontSize="sm"
          >
            {error}
          </Text>
        </>
      )}
    </VStack>
  );
};

export default SimpleSelect;
