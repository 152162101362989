import React, { useEffect, useRef } from 'react';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import trashSvg from 'assets/svg/trash.svg';
import { isImage } from 'utils/functions';

interface UploadInputProps {
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (fileName: string, fileId?: string) => void;
  images: ImageProps[] | null;
  label: string;
  actionText: string;
  imageError?: boolean;
  errorText?: string;
  accept?: string;
  hide?: boolean;
}

export const UploadInput = ({
  handleImageChange,
  images,
  label,
  actionText,
  imageError = false,
  errorText,
  accept = 'image/*',
  handleDelete,
  hide = false,
}: UploadInputProps) => {
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input.current) input.current.value = '';
  }, [images]);

  return (
    <Box w="100%" display="flex" flexDir="column">
      <Text color="text.300" fontSize="sm">
        {label}
      </Text>
      {!hide && (
        <Flex
          mt="4px"
          w="100%"
          align="center"
          justify="space-between"
          borderColor="text.100"
          borderStyle="dashed"
          borderWidth="1px"
          rounded="md"
          shadow="sm"
          role="group"
          px="24px"
          h="48px"
          transition="all 150ms ease-in-out"
          _hover={{
            shadow: 'md',
          }}
          position="relative"
        >
          <Text fontSize="12px" color="text.100">
            {actionText}
          </Text>
          <Input
            data-cy="upload-input"
            ref={input}
            cursor="pointer"
            type="file"
            h="100%"
            width="100%"
            position="absolute"
            multiple
            top="0"
            left="0"
            bottom="0"
            opacity="0"
            aria-hidden="true"
            accept={accept}
            border="3px solid"
            onChange={e => handleImageChange(e)}
          />
          <Flex bg="grey.200" py="8px" px="10px" rounded="md">
            <Text fontSize="14px" fontWeight="normal" color="black">
              Upload
            </Text>
          </Flex>
        </Flex>
      )}

      {imageError && (
        <>
          <VisuallyHidden>
            <Text>Erro, {errorText}</Text>
          </VisuallyHidden>
          <Text
            mt="4px"
            aria-hidden="true"
            alignSelf="start"
            pl="8px"
            color="red.200"
            fontSize="sm"
          >
            {errorText}
          </Text>
        </>
      )}

      {images?.map(
        file =>
          file.path && (
            <Flex mt="8px" key={file.path} w="100%" align="center">
              <Box>
                {isImage(file.name) ? (
                  <Image
                    width="32px"
                    height="32px"
                    rounded="md"
                    src={file.path || ''}
                  />
                ) : (
                  <Icon
                    width="24px"
                    height="24px"
                    as={BsFileEarmarkRichtext}
                    color="blue.0"
                  />
                )}
              </Box>
              <Text noOfLines={1} ml="8px" color="black" wordBreak="break-all">
                {file.name}
              </Text>
              <Button
                bg="transparent"
                p="0"
                _hover={{ bg: 'transparent' }}
                onClick={() => handleDelete(file.name, file.id)}
              >
                <Image src={trashSvg} />
              </Button>
            </Flex>
          ),
      )}
    </Box>
  );
};
