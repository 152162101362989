/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { DataTable, Card } from 'components';
import format from 'date-fns/format';
import { useDateSelect, useAnalyticsEvent, useCustomToast } from 'hooks';
import { GA_DOWNLOAD_LABEL } from 'utils/constants';
import { formatPlate, isPlate } from 'utils/formaters';
import MobileHeader from './MobileHeader';
import WebHeader from './WebHeader';

interface TableCardsProps<T = any> extends DataTableProps<T> {
  title?: string;
  customTitle?: ReactNode;
  subtitleMb?: string | number;
  subtitle?: string;
  step?: number | null;
  hasGlobalSearch?: boolean;
  fetchDownload?: () => Promise<void>;
  error?: unknown;
  analyticsId?: string;
  setDateRange?: React.Dispatch<React.SetStateAction<DateProps[]>>;
  initDate?: DateProps[];
  isError?: boolean;
  openToken?: () => void;
  searchHolder?: string;
  handleFilter?: () => void;
  setCostCenter?: React.Dispatch<React.SetStateAction<SelectOption | null>>;
  hasPlateSearch?: boolean;
  customComponent?: any;
  filterPerDay?: boolean;
}

const TableCard = forwardRef<string | undefined, TableCardsProps>(
  (
    {
      title,
      customTitle,
      subtitleMb,
      subtitle,
      hasGlobalSearch,
      costCenters,
      fetchDownload,
      analyticsId,
      fetchData,
      initDate,
      startDate,
      endDate,
      setDateRange,
      isError,
      hasPlateSearch,
      data,
      searchHolder,
      handleFilter,
      setCostCenter,
      costCenter,
      step,
      openToken,
      filters,
      customComponent,
      filterPerDay,
      ...rest
    },
    ref,
  ) => {
    const { handleToast } = useCustomToast();

    const { onDateRangeChange } = useDateSelect(setDateRange);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchPlates, setSearchPlates] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);

    const stringToDate = (date: string) => {
      if (date) {
        const regex = /(\d{2}\/\d{2}\/\d{4})/;
        const newDate = date.match(regex)?.[0];

        return new Date(newDate || '');
      }

      return new Date();
    };

    const filteredData = useMemo(() => {
      if (costCenter && data) {
        const filteredItens = data?.itens.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => item.name === costCenter.name,
        );

        return {
          ...data,
          rows: data.rows,
          itens: filteredItens.sort(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (a: any, b: any) =>
              stringToDate(b.date).getTime() - stringToDate(a.date).getTime(),
          ),
        };
      } else return data;
    }, [data, costCenter]);

    useImperativeHandle(ref, () => searchTerm, [searchTerm]);

    const analyticsDownloadEvent = useAnalyticsEvent({
      category: 'User',
      action: GA_DOWNLOAD_LABEL,
    });

    const internalOnDownload = async () => {
      if (fetchDownload) {
        try {
          setDownloadLoading(true);

          await fetchDownload();

          handleToast({
            step: 5,
            action: 'O download do arquivo foi concluído',
          });
        } catch {
          handleToast({
            step: 4,
            action: 'fazer o download do arquivo',
            helpText:
              'Por favor, tente novamente mais tarde ou contate o suporte',
          });
        } finally {
          if (analyticsId) analyticsDownloadEvent(analyticsId);
          setDownloadLoading(false);
        }
      }
    };

    const handlePlate = (plateSearch: string) => {
      setSearchPlates(plates => plates.filter(plate => plateSearch !== plate));
    };

    const headerProps = useMemo(
      () => ({
        downloadLoading,
        inputValue,
        searchPlates,
        removeSearchPlates: handlePlate,
        hasPlateSearch,
        filters,
        hasGlobalSearch,
        costCenters,
        initDate,
        isError,
        searchHolder,
        handleCostCenter: setCostCenter,
        costCenter,
        internalOnDownload,
        setSearchTerm: setInputValue,
        fetchDownload,
        setDateRange,
        onDateRangeChange,
        handleFilter,
        customComponent,
        filterPerDay,
      }),
      [
        costCenter,
        hasPlateSearch,
        filters,
        hasGlobalSearch,
        costCenters,
        initDate,
        isError,
        searchHolder,
        inputValue,
        downloadLoading,
        searchTerm,
        searchPlates,
        customComponent,
        filterPerDay,
      ],
    );

    useEffect(() => {
      if (costCenters) setCostCenter?.(costCenters[0]);
    }, [costCenters]);

    useEffect(() => {
      const handler = setTimeout(() => {
        if (
          isPlate(inputValue) &&
          hasPlateSearch &&
          !searchPlates.includes(formatPlate(inputValue))
        ) {
          setSearchPlates(plates => [...plates, formatPlate(inputValue)]);
          setInputValue('');
        } else {
          setSearchTerm(inputValue);
        }
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    }, [inputValue, hasPlateSearch, searchPlates]);

    return (
      <Card {...rest} overflowX="hidden">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'column', md: 'center' }}
          justifyContent="space-between"
        >
          <Box>
            {customTitle || (
              <Text
                alignSelf="center"
                color="text.100"
                fontSize="16px"
                fontWeight={400}
              >
                {title}
              </Text>
            )}

            <Text
              textAlign="start"
              mb={{ base: '18px', md: subtitleMb || '22px' }}
              color="text.300"
              fontSize="12px"
              fontWeight={500}
            >
              {subtitle || ''}
            </Text>
          </Box>

          {!isError && data && (
            <>
              <WebHeader {...headerProps} step={step} />
              <MobileHeader {...headerProps} subtitleMb={subtitleMb} />
            </>
          )}
        </Flex>

        <DataTable
          filters={filters}
          isError={isError}
          costCenter={costCenter}
          costCenters={costCenters}
          totalLines={data?.totalLines}
          fromCard
          data={filteredData}
          openToken={openToken}
          searchTerm={searchTerm}
          searchPlates={searchPlates}
          fetchData={fetchData}
          overflowX="auto"
          w="100%"
          startDate={
            initDate
              ? format(
                  new Date(initDate[0].startDate || new Date()),
                  'yyyy-MM-dd',
                )
              : startDate
          }
          endDate={
            initDate
              ? format(
                  new Date(initDate[0].endDate || new Date()),
                  'yyyy-MM-dd',
                )
              : endDate
          }
          {...rest}
        />
      </Card>
    );
  },
);

TableCard.displayName = 'TableCard';

export default TableCard;
