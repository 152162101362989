import React from 'react';
import { HiOutlineExclamation } from 'react-icons/hi';
import {
  Modal,
  Button,
  Center,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useDeleteCommuniqueMutation } from '@spartalabs/pdc-core';
import { useCustomToast } from 'hooks';

export const DeleteModal: React.FC<{
  id: string | null;
  onClose: () => void;
  title: string;
  desc?: string;
  actionText?: string;
}> = ({
  onClose,
  id,
  title,
  desc = 'Após apagar o registro não será possível recuperar os dados',
  actionText = 'Apagar',
}) => {
  const [deleteCat, { isLoading }] = useDeleteCommuniqueMutation();
  const { handleToast } = useCustomToast();

  const handleDelete = async () => {
    try {
      if (id) await deleteCat({ id }).unwrap();
      handleToast({
        step: 5,
        action: 'Comunicado deletado com sucesso',
      });
      onClose();
    } catch {
      handleToast({
        step: 4,
        action: 'deletar o comunicado',
      });
    }
  };

  return (
    <Modal isCentered isOpen={id ? true : false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '80%', md: '520px' }}
        py="24px"
        px="18px"
        bg="white"
      >
        <ModalCloseButton borderRadius="36px" color="black" />

        <ModalBody alignItems="center" py="0px " px="0px">
          <Center>
            <Icon
              borderRadius="100px"
              padding="16px"
              bg="grey.200"
              w="80px"
              h="80px"
              as={HiOutlineExclamation}
              color="grey.600"
            />
          </Center>
          <Text
            textAlign="center"
            mt="16px"
            p="0px"
            fontSize="lg"
            fontWeight={800}
            color="black"
            alignSelf="center"
          >
            {title}
          </Text>
          <Text
            textAlign="center"
            p="0px 0 0px 0"
            fontSize="sm"
            color="grey.600"
          >
            {desc}
          </Text>
        </ModalBody>

        <ModalFooter mt="32px" p="0px">
          <Button variant="outline" onClick={onClose}>
            Voltar
          </Button>
          <Button isLoading={isLoading} onClick={handleDelete} ml="16px">
            {actionText || 'Apagar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
