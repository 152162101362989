import React, { useEffect } from 'react';
import { forwardRef, useRef, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { IoMdCheckmark } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { Input, InputProps, Text, Icon, Flex } from '@chakra-ui/react';

interface SimpleEditableProps extends InputProps {
  value: string;
  onDelete?: () => void;
  onEdit: (value: string) => void;
}

const SimpleEditable = forwardRef<HTMLInputElement | null, SimpleEditableProps>(
  ({ value, onEdit, onDelete }) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value || '');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleEdit = () => {
      setEdit(true);

      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    };

    const handleCancel = () => {
      setInputValue(value);
      setEdit(false);
    };

    const handleSubmit = () => {
      if (inputValue.trim() !== '') {
        onEdit(inputValue);
        setEdit(false);
      }
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <Flex w="100%" align="center">
        {edit ? (
          <>
            <Input
              fontSize="14px"
              textColor="text.200"
              w="40%"
              p={0}
              m={0}
              h={5}
              ref={inputRef}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              borderRadius={0}
              disabled={!edit}
              border="none"
              borderBottom={edit ? '1px solid blue' : 'none'}
              mr={4}
              ml={2}
            />
            <Icon
              mr={2}
              as={IoMdCheckmark}
              color="green"
              h="20px"
              w="20px"
              alignSelf="center"
              onClick={handleSubmit}
            />
            <Icon
              onClick={handleCancel}
              w="20px"
              h="20px"
              color="black"
              as={MdOutlineCancel}
            />
          </>
        ) : (
          <>
            <Text fontSize="14px" mr={4} ml={2} color="text.200">
              {value || 'Nova opção'}
            </Text>
            <Icon
              mr={2}
              onClick={handleEdit}
              w="16px"
              h="16px"
              color="gray.500"
              cursor="pointer"
              _hover={{ color: 'gray.600' }}
              as={HiOutlinePencil}
            />
            {onDelete && (
              <Icon
                as={FiTrash}
                color="black"
                h="16px"
                w="16px"
                cursor="pointer"
                _hover={{ color: 'red.500' }}
                onClick={onDelete}
              />
            )}
          </>
        )}
      </Flex>
    );
  },
);

SimpleEditable.displayName = 'Editable';

export default SimpleEditable;
